@import './base.css';
@import './components.css';
@import './utilities.css';

:root {
  --neutral-solid-700: hsla(0, 0%, 56%, 1);

  --novel-black: rgb(0 0 0);
  /* --novel-white: rgb(255 255 255); */
  --novel-stone-50: #e5e5e5;
  --novel-stone-100: #dbdbdb;
  --novel-stone-200: #c7c7c7;
  --novel-stone-300: #b2b2b2;
  --novel-stone-400: #9e9e9e;
  --novel-stone-500: #8a8a8a;
  --novel-stone-600: #757575;
  --novel-stone-700: #616161;
  --novel-stone-800: #4c4c4c;
  --novel-stone-900: #383838;

  --novel-highlight-default: #ffffff;
  --novel-highlight-purple: #f6f3f8;
  --novel-highlight-red: #fdebeb;
  --novel-highlight-yellow: #fbf4a2;
  --novel-highlight-blue: #c1ecf9;
  --novel-highlight-green: #acf79f;
  --novel-highlight-orange: #faebdd;
  --novel-highlight-pink: #faf1f5;
  --novel-highlight-stone: #f1f1ef;

  --background: 0 0% 100%;
  --foreground: 240 10% 3.9%;

  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 46.1%;

  --popover: 0 0% 100%;
  --popover-foreground: 240 10% 3.9%;

  --card: 0 0% 100%;
  --card-foreground: 240 10% 3.9%;

  --border: 240 5.9% 90%;
  --input: 240 5.9% 90%;

  --primary: 240 5.9% 10%;
  --primary-foreground: 0 0% 98%;

  --secondary: 240 4.8% 95.9%;
  --secondary-foreground: 240 5.9% 10%;

  --accent: 240 4.8% 95.9%;
  --accent-foreground: ;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;

  --ring: 240 5% 64.9%;

  --radius: 0.5rem;
}

.dark-theme {
  --novel-black: rgb(255 255 255);
  --novel-white: rgb(25 25 25);
  --novel-stone-50: #383838;
  --novel-stone-100: #4c4c4c;
  --novel-stone-200: #616161;
  --novel-stone-300: #757575;
  --novel-stone-400: #8a8a8a;
  --novel-stone-500: #9e9e9e;
  --novel-stone-600: #b2b2b2;
  --novel-stone-700: #c7c7c7;
  --novel-stone-800: #dbdbdb;
  --novel-stone-900: #e5e5e5;

  --novel-highlight-default: #000000;
  --novel-highlight-purple: #3f2c4b;
  --novel-highlight-red: #5c1a1a;
  --novel-highlight-yellow: #5c4b1a;
  --novel-highlight-blue: #1a3d5c;
  --novel-highlight-green: #1a5c20;
  --novel-highlight-orange: #5c3a1a;
  --novel-highlight-pink: #5c1a3a;
  --novel-highlight-stone: #3a3a3a;

  --background: 240 10% 3.9%;
  --foreground: 0 0% 98%;

  --muted: 240 3.7% 15.9%;
  --muted-foreground: 240 5% 64.9%;

  --popover: 240 10% 3.9%;
  --popover-foreground: 0 0% 98%;

  --card: 240 10% 3.9%;
  --card-foreground: 0 0% 98%;

  --border: 0 0% 15.9%;
  --input: 240 3.7% 15.9%;

  --primary: 0 0% 98%;
  --primary-foreground: 240 5.9% 10%;

  --secondary: 240 3.7% 15.9%;
  --secondary-foreground: 0 0% 98%;

  --accent: 240 3.7% 15.9%;
  --accent-foreground: ;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 0 85.7% 97.3%;

  --ring: 240 3.7% 15.9%;
}

/* *,
  *:before,
  *:after {
    box-sizing: inherit;
  } */

/* html {
    height: 100%;
    box-sizing: border-box;
    touch-action: manipulation;
    font-feature-settings: 'case' 1, 'rlig' 1, 'calt' 0;
  } */

html,
body,
#root {
  /* text-rendering: optimizeLegibility; */
  /* -moz-osx-font-smoothing: grayscale; */
  /* @apply text-white  antialiased; */
  /* background-color: var(--novel-white); */
  /* height: 100%; */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  /* font-feature-settings: 'cv11', 'salt', 'ss01', 'ss03', 'cv01', 'cv02', 'cv03',
      'cv04', 'cv05', 'cv06', 'cv09', 'cv10'; */
}

/* For removing autocomplete highlight color in chrome (note: use this at bottom of your css file). */

/* body {
  margin: 0;
  padding: 0;
} */

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.height-screen-helper {
  height: calc(100vh - 80px);
}

input,
textarea {
  @apply caret-brand-600 dark:caret-brand-400;
}

.shadow-box {
  box-shadow: 0 0 50px -12px rgba(208, 121, 249, 0.3);
}
.shadow-box2 {
  box-shadow: 0 0 50px -12px rgba(121, 247, 249, 0.3);
}

.input-shadow {
  --tw-shadow: 0px 1px 0px -1px var(--tw-shadow-color),
    0px 1px 1px -1px var(--tw-shadow-color),
    0px 1px 2px -1px var(--tw-shadow-color),
    0px 2px 4px -2px var(--tw-shadow-color),
    0px 3px 6px -3px var(--tw-shadow-color);
  --tw-shadow-colored: 0px 1px 0px -1px var(--tw-shadow-color),
    0px 1px 1px -1px var(--tw-shadow-color),
    0px 1px 2px -1px var(--tw-shadow-color),
    0px 2px 4px -2px var(--tw-shadow-color),
    0px 3px 6px -3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.input-shadow-glow::after {
  --tw-shadow: inset 0px 0px 0px 1px var(--tw-shadow-color),
    inset 0px 1px 0px var(--tw-shadow-color);
  --tw-shadow-colored: inset 0px 0px 0px 1px var(--tw-shadow-color),
    inset 0px 1px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.border {
  background-clip: padding-box;
}

.shadow-massive {
  --tw-shadow: 0px 64px 64px rgba(0, 0, 0, 0.25),
    0px 32px 32px rgba(0, 0, 0, 0.25), 0px 16px 16px rgba(0, 0, 0, 0.25),
    0px 8px 8px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  --tw-shadow-colored: 0px 64px 64px var(--tw-shadow-color),
    0px 32px 32px var(--tw-shadow-color), 0px 16px 16px var(--tw-shadow-color),
    0px 8px 8px var(--tw-shadow-color), 0px 4px 4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.bg-card {
  --tw-bg-opacity: 1;
  background-color: rgb(19 19 19 / var(--tw-bg-opacity));
}

/* .glow-button {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.16) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      var(--novel-stone-900);
    
  
    transition: 150ms all;
  } */

/* width */
::-webkit-scrollbar {
  width: 0px;
  border-radius: 25px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--novel-stone-100);
  border-radius: 25px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--novel-stone-200);
  border-radius: 25px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.card:hover {
  transform: rotateY(180deg);
  transform-style: preserve-3d;
}

.card-back {
  transform: rotateY(180deg);
}

.flashcard:last-child {
  margin-right: 60px;
}

.flashcard-container {
  font-size: 1rem;
}

.flashcard {
  height: 24rem;
  width: 24rem;
  margin: 6px;
}

.flashcard:first-child {
  margin-left: 20px;
}

.flashcard:last-child {
  margin-right: 20px;
}

/* Mobile Styles */
@media (max-width: 640px) {
  .flashcard-container {
    font-size: 0.8rem;
    touch-action: pan-y; /* This allows vertical scrolling while preventing horizontal scrolling */
  }

  .flashcard {
    height: 40vh;
    max-height: 300px;
    width: 90vw;
    max-width: 280px;
    margin: 4px;
  }

  .flashcard:first-child {
    margin-left: 2vw;
  }

  .flashcard:last-child {
    margin-right: 2vw;
  }
}

/* Tablet Styles */
@media (min-width: 641px) and (max-width: 1024px) {
  .flashcard-container {
    font-size: 1rem;
  }

  .flashcard {
    height: 50vh;
    width: 60vw;
    margin: 6px;
  }

  .flashcard:first-child {
    margin-left: 20px;
  }

  .flashcard:last-child {
    margin-right: 20px;
  }
}

.masonry-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: auto;
  gap: 16px;
}

.supabase-auth-ui_ui-container {
  display: none;
}

.sign-in {
  background-color: white;
}

#auth-sign-in {
  display: none;
}

.supabase-auth-ui_ui-divider {
  display: none !important;
}

.moving-grid-background {
  will-change: transform;
  background-size: 3rem 3rem;
  background-image: linear-gradient(to right, #8882 1px, transparent 1px),
    linear-gradient(to bottom, #8882 1px, transparent 1px);
  animation: movingbackground 90s linear infinite !important;
}

.features-background-mask {
  mask-image: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 1) 100%
  );
  -webkit-mask-image: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 1) 100%
  );
}

.animated-feature-cards::before {
  @apply pointer-events-none absolute select-none rounded-3xl opacity-0 transition-opacity duration-300 hover:opacity-100;
  background: radial-gradient(
    1000px circle at var(--x) var(--y),
    #ecc02325 0,
    #ecc02350 25%,
    rgba(255, 255, 255, 0) 50%,
    transparent 80%
  );
  z-index: -1;
  content: '';
  inset: -1px;
}

#container {
  mask: linear-gradient(90deg, #0000, #000 10% 80%, #0000);
  -webkit-mask: linear-gradient(90deg, #0000, #000 10% 80%, #0000);
}

@media (min-width: 1400px) {
  #container {
    /* here we calculate the diffrence between screen width and the container width (1400px)
    then split that on half to make carousel align left with the rest of the content and i added some rems to aligned perfectly */
    padding-left: calc(((100vw - 1400px) + 12rem) / 2);
  }
}

@media (max-width: 768px) {
  .animated-feature-cards::before {
    background: none !important;
  }
}

@keyframes movingbackground {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -50%);
  }
}

@media (prefers-reduced-motion: reduce) {
  .moving-grid-background {
    animation: none !important;
  }
}

.container {
  @apply mx-auto max-w-[1400px] px-4 md:px-24;
}

@property --gradient-angle {
  syntax: '<angle>'; /* <- defined as type angle for the transition to work */
  initial-value: 360deg;
  inherits: false;
}

.animate-newstar {
  opacity: 0;
  animation: newstar 1.5s 0.95s ease 1 forwards;
}
.animate-oldstar {
  opacity: 1;
  animation: oldstar 1.5s 0.95s ease 1 forwards;
}

@keyframes newstar {
  to {
    opacity: 1;
  }
}
@keyframes oldstar {
  to {
    opacity: 0;
  }
}

.animate-bg-gradient-to-center {
  animation: bg-to-center 1s 0.75s ease 1 forwards;
}
.animate-bg-gradient-to-center-title {
  animation: bg-to-center 1s 0.95s ease 1 forwards;
}

@keyframes bg-to-center {
  to {
    background-position: center;
  }
}

.animate-logo-light {
  filter: brightness(90%);
  animation: logo-light 1.25s 0.85s ease 1 forwards;
}
@keyframes logo-light {
  to {
    filter: brightness(110%);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #958f7a;
  transition: background-color 5000s ease-in-out 0s;
}

/* DRAW */
.Stack.Stack_vertical.App-menu_top__left {
  display: none;
}
.ToolIcon.ToolIcon__lock.ToolIcon_size_medium {
  display: none;
}

.dropdown-menu-button.App-toolbar__extra-tools-trigger.zen-mode-transition {
  display: none;
}

.sidebar-trigger {
  display: none !important;
}

.default-sidebar-trigger {
  display: none !important;
}

input[type='radio'] {
  visibility: hidden;
  height: 0;
  width: 0;
}
