@tailwind base;

@font-face {
  font-family: 'Mona Sans';
  font-weight: 200 900;
  font-sans: block;
  font-style: normal;
  font-stretch: 75% 125%;
  src: url('../fonts/Mona-Sans.var.woff2') format('woff2');
}
